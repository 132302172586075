import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Panel from 'components/Panel'
import { useSnackbar } from 'notistack'
import { setInfo } from 'slices/infoSlice'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import { format } from 'date-fns';
import { warpUpFailResponse } from 'utilities/utils'
import { differenceWith } from 'lodash';
import Button from 'components/Button'
import { setInfoById } from 'slices/infoSlice'
import Task from './task'
import { Box } from '@mui/material'
import AutoComplete from 'components/AutoComplete'
import DatePicker from 'components/DatePicker'
import SearchIcon from '@mui/icons-material/Search';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { toBuddhistYear } from 'utilities/utils';
import EllipsisTextComponent from 'components/EllipsisTextComponent';
import { createRoot } from 'react-dom/client'
import { DataSet } from 'vis-data'

export default function Schedule() {
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const { post, multiple } = useAxios();
    const mode = useState("initial")
    const info = useSelector(state => state.info.ctx)
    const { confirmPopup } = usePopup();
    const [reverseItems, setReverseItems] = useState([]);
    const [reverseDraggableItems, setReverseDraggableItems] = useState([]);
    const [vehicleGroup, setVehicleGroup] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [item, setItem] = useState(null)
    const [allVehicleType, setAllVehicleType] = useState([]);
    const [outstandingTask, setOutStandingTask] = useState([]);
    const [min, setMin] = useState(() => {
        const date = new Date();
        date.setDate(date.getDate() - 1);
        date.setHours(0, 0, 0, 0);
        return date;
    });

    const [max, setMax] = useState(() => {
        const date = new Date();
        date.setDate(date.getDate() + 1);
        date.setHours(23, 59, 59, 0);
        return date;
    });

    useEffect(() => {
        const controller = new AbortController()
        fetchData(controller)

        return () => {
            controller.abort()
        }
    }, [])

    const buildContent = (item, showDeliveryDate) => {
        return <EllipsisTextComponent text={`${showDeliveryDate ? (item?.start ? format(toBuddhistYear(new Date(item.start)), 'HH:mm') : "-") + " | " + (item?.data?.vehicleTypeName || "-") + " | " : ""}${item?.data?.customerName || "-"} | ${item?.data?.driverName || "-"} | ${item?.data?.temperature || "-"}`} popoverText={
            <>
                <p>ประเภทรถ: {item?.data?.vehicleTypeName}</p>
                <p>ลูกค้า: {item?.data?.customerName}</p>
                <p>รับงาน: {item?.start ? format(toBuddhistYear(new Date(item.start)), 'dd/MM/yyyy HH:mm') : "-"}</p>
                <p>ส่งงาน: {item?.end ? format(toBuddhistYear(new Date(item.end)), 'dd/MM/yyyy HH:mm') : "-"}</p>
                <EllipsisTextComponent text={`พนักงานขับรถ: ${item?.data?.driverName || "-"}`} width={250} />
                <EllipsisTextComponent text={`พนักงานขับรถ (สำรอง): ${item?.data?.driverName2 || "-"}`} width={250} />
                <EllipsisTextComponent text={`พนักงานยกของ: ${item?.data?.workerName || "-"}`} width={250} />
                <EllipsisTextComponent text={`พนักงานยกของ (สำรอง): ${item?.data?.workerName2 || "-"}`} width={250} />
                <p>อุณหภูมิ: {item?.data?.temperature || "-"}</p>
                <EllipsisTextComponent text={`ต้นทาง: ${item?.data?.pickupAddress}`} width={250} />
                <EllipsisTextComponent text={`ปลายทาง: ${item?.data?.shippingAddress}`} width={250} />
            </>
        } width={"100%"} />
    }

    const fetchData = async controller => {
        const result = await multiple([
            { method: "get", url: "/job/getAllPlannedJob", params: { vehicleTypeId: info.filterVehicleTypeId, startDate: info.filterStartDate, endDate: info.filterEndDate }, config: { signal: controller.signal } },
            { method: "get", url: "/vehicleType/getAllActiveVehicleType", config: { signal: controller.signal } },
        ])

        if (result[0].status === 200) {
            const data = result[0].data.data || {}
            const jobs = data.jobs.map(e => ({ ...e, start: new Date(e.start), end: new Date(e.end) }))
            const groups = data.groups || []

            const draggableItems = jobs.filter(e => e.group === 0).map(item => ({
                ...item,
                onDoubleClick: () => taskDoubleClick(item, true),
                content: buildContent(item, true),
                draggableClassName: "bg-[#D6EFD880]"
            })).flat()

            setOutStandingTask(draggableItems)
            setReverseDraggableItems(draggableItems)

            const vehicleGroup = groups.filter(e => e.group !== 0)
            setVehicles(vehicleGroup.filter(e => e.type === "vehicle").map(e => ({ label: e.data.lcnNo, value: e.data.id, data: e.data })).sort((a, b) => a.data.vehicleTypeName.localeCompare(b.data.vehicleTypeName)))
            setVehicleGroup(vehicleGroup)
            setReverseItems(jobs.filter(e => e.group !== 0))
            dispatch(setInfo({ scheduleJob: jobs.filter(e => e.group !== 0), scheduleJobDataset: new DataSet() }))
        }
        if (result[1].status === 200) {
            setAllVehicleType(result[1].data.data.records.map(data => ({ label: data.name, value: data.id })) || [])
        }
    }

    const saveScheduleJob = (tasks, callbackSuccess, callbackReject) => {
        confirmPopup({
            title: "แจ้งเตือน",
            content: "ท่านต้องการแก้ไขหรือไม่",
            onSubmit: async () => {
                const oldArr = reverseItems
                const newArr = tasks
                const data = differenceWith(newArr, oldArr, comparator)
                let response
                response = await post("/job/updateSchedule", {
                    jobs: data?.map(e => ({
                        id: e.id,
                        etdTime: e.start ? format(new Date(e.start), 'yyyy-MM-dd HH:mm:ss') : null,
                        etaTime: e.end ? format(new Date(e.end), 'yyyy-MM-dd HH:mm:ss') : null,
                        takeCarTime: e.data.takeCarTime ? format(new Date(e.data.takeCarTime), 'yyyy-MM-dd HH:mm:ss') : null,
                        driverId: e.data.driverId,
                        driverId2: e.data.driverId2,
                        workerId: e.data.workerId,
                        workerId2: e.data.workerId2,
                        vehicleTypeId: e.data.vehicleTypeId,
                        vehicleId: e.group ? e.group : null,
                    }))
                })

                if (response.status === 200) {
                    enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
                    const controller = new AbortController()
                    fetchData(controller)
                    callbackSuccess()
                } else {
                    enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
                }
            },
            onCancel: callbackReject
        })
    }

    const dispatchJob = () => {
        confirmPopup({
            title: "แจ้งเตือน",
            content: "ท่านต้องการปล่อยงานทั้งหมดหรือไม่",
            onSubmit: async () => {
                let response
                response = await post("/job/dispatchJob", {
                    jobs: reverseItems?.filter(e => !e.dispatchJob && e.group != 0)?.map(e => ({
                        id: e.id
                    }))
                })

                if (response.status === 200) {
                    enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
                    const controller = new AbortController()
                    fetchData(controller)
                } else {
                    enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
                }
            },
        })
    }

    const comparator = (obj1, obj2) => {
        const result = obj1.id === obj2.id
            && obj1.group === obj2.group
            && obj1.start === obj2.start
            && obj1.end === obj2.end
            && obj1.driverId === obj2.driverId
            && obj1.driverId2 === obj2.driverId2
            && obj1.workerId === obj2.workerId
            && obj1.workerId2 === obj2.workerId2
            && obj1.routeId === obj2.routeId
            && obj1.takeCarTime === obj2.takeCarTime;
        return result;
    }

    const customTooltipContent = (event) => {
        return `เริ่มต้น: ${format(toBuddhistYear(new Date(event.start)), 'dd/MM/yyyy HH:mm')}<br />สิ้นสุด: ${format(toBuddhistYear(new Date(event.end)), 'dd/MM/yyyy HH:mm')}`
    }

    const taskDoubleClick = (item, isAdd = false) => {
        dispatch(setInfoById({ id: "taskModal", payload: { ...item.data, etdTime: item?.start, etaTime: item?.end } }))
        setItem(item)
        mode[1](isAdd ? "add" : "edit")
    }

    const clearFilterJobs = async () => {
        dispatch(setInfo({ filterVehicleTypeId: null, filterStartDate: null, filterEndDate: null }))
    }

    const filterJobs = async () => {
        fetchData(new AbortController())
    }

    const scheduleJob = useMemo(() => [
        {
            rowData: [
                {
                    type: "timeline",
                    props: {
                        id: "scheduleJob",
                        datasetId: "scheduleJobDataset",
                        options: {
                            editable: {
                                add: true,
                                updateTime: true,
                                updateGroup: true,
                                remove: false,
                                overrideItems: false
                            },
                            onAdd: (item, callback, successCallback) => {
                                if (item.data && !vehicleGroup.filter(e => e.type === "vehicleType").map(e => e.id).includes(item.group)) {
                                    item.data.vehicleId = item.group
                                    item.data.vehicleTypeId = vehicles.find(e => e.value === item.group)?.data.vehicleTypeId;
                                    item.data.vehicleTypeName = vehicles.find(e => e.value === item.group)?.data.vehicleTypeName;
                                    callback(item)
                                    successCallback()
                                }
                            },
                            itemsAlwaysDraggable: true,
                            horizontalScroll: false,
                            verticalScroll: true,
                            orientation: "top",
                            maxHeight: '60vh',
                            multiselect: true,
                            multiselectPerGroup: true,
                            preferZoom: true,
                            stack: true,
                            zoomMax: 7 * 24 * 60 * 60 * 1000,
                            zoomMin: 1 * 24 * 60 * 60 * 1000,
                            min: info.filterStartDate ? new Date(new Date(info.filterStartDate + " 00:00").setDate(new Date(info.filterStartDate + " 00:00").getDate() - 1)) : min,
                            max: info.filterEndDate ? new Date(new Date(info.filterEndDate + " 00:00").setDate(new Date(info.filterEndDate + " 00:00").getDate() + 1)) : max,
                            snap: (date) => {
                                const minute = 60 * 1000 * 1
                                return Math.round(date / minute) * minute;
                            },
                            tooltipOnItemUpdateTime: {
                                template: customTooltipContent
                            },
                            template: (item, element, data) => {
                                if (!element._root) {
                                    element._root = createRoot(element);
                                }
                                element._root.render(buildContent(item))
                                return "≣"
                            }
                        },
                        draggable: {
                            content: outstandingTask,
                            title: `งานค้างทั้งหมด`,
                            reverseItems: reverseDraggableItems
                        },
                        group: vehicleGroup,
                        onSave: saveScheduleJob,
                        customButton: <Button className="!mr-2" onClick={dispatchJob}>ปล่อยงาน</Button>,
                        reverseItems: reverseItems,
                        onDoubleClick: taskDoubleClick
                    }
                }
            ]
        },
    ], [vehicleGroup, outstandingTask, reverseItems, reverseDraggableItems])

    return (
        <>
            <Box className='flex justify-end gap-2 mb-2'>
                <AutoComplete
                    id="filterVehicleTypeId"
                    label="ประเภทรถ"
                    className="!w-[200px]"
                    options={allVehicleType}
                />
                <DatePicker
                    id="filterStartDate"
                    label="ส่งสินค้าตั้งแต่วันที่"
                    maxDate={info.filterEndDate}
                    className="max-w-[200px]"
                >
                </DatePicker>
                <DatePicker
                    id="filterEndDate"
                    label="ถึงวันที่"
                    minDate={info.filterStartDate}
                    className="max-w-[200px]"
                >
                </DatePicker>
                <Button onClick={clearFilterJobs} variant="text"><ClearAllIcon /></Button>
                <Button onClick={filterJobs} variant="text"><SearchIcon /></Button>
            </Box>
            <Panel data={scheduleJob}></Panel>
            <Task mode={mode} item={item} vehicles={vehicles} callback={(item) => {
                setOutStandingTask(outstandingTask.filter(e => e.data.id !== item.id))
            }} />
        </>
    )
}
