import { Box } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { useSnackbar } from 'notistack'
import { setInfo, clearInfoById, setInfoById } from 'slices/infoSlice'
import { normalizeData } from 'utilities/validator'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import { STATUS_ORDER_COMPLETE, STATUS_JOB_IN_TRANSIT, STATUS_JOB_REJECT, STATUS_JOB_COMPLETE, STATUS_ORDER_PLANNED, STATUS_JOB_CANCELED, STATUS_JOB_PARTIAL_COMPLETE, STATUS_JOB_NEW } from 'helper/DataFactory'
import AdvanceSearch from 'components/AdvanceSearch'
import { warpUpFailResponse } from 'utilities/utils'
import useDataHandler from 'hooks/useDataHandler'
import { memoizedSelectAdvanceInfo } from 'redux/memorize'
import copy from "clipboard-copy";
import moment from 'moment'
import { format } from 'date-fns'
import { toBuddhistYear } from 'utilities/utils'
import { th } from 'date-fns/locale';

export default function EditJob({ setJobMode }) {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar();
	const { post, get, download, multiple } = useAxios();
	const { save } = useDataHandler()
	const info = useSelector(state => state.info)
	const paging = useState({})
	const [mode, setMode] = useState("initial")
	const searchInfo = useSelector(memoizedSelectAdvanceInfo)
	const { confirmPopup } = usePopup();
	const [allTruckType, setAllTruckType] = useState([])
	const [allLcnNo, setAllLcnNo] = useState([])
	const [allRoute, setAllRoute] = useState([])
	const [allVendor, setAllVendor] = useState([])
	const [allDriver, setAllDriver] = useState([])
	const [allWorker, setAllWorker] = useState([])
	const [allJob, setAllJob] = useState([])
	const [allOrder, setAllOrder] = useState([])
	const [allCompleteOrder, setAllCompleteOrder] = useState([])
	const [allReason, setAllReason] = useState([]);
	const [allVehicleType, setAllVehicleType] = useState([]);
	const [allCustomer, setAllCustomer] = useState([])
	const roleUser = useSelector(state => state.user.roleName)
	const finalStatus = [STATUS_JOB_COMPLETE, STATUS_JOB_PARTIAL_COMPLETE, STATUS_JOB_REJECT, STATUS_JOB_CANCELED]
	const [allAdditionalPrice, setAllAdditionalPrice] = useState([]);
	const search = async (data) => {
		const response = await get("/order/getOrderToPlan", { jobId: info.ctx.id, vehicleTypeId: info.ctx.vehicleTypeId, divisionId: info.ctx.divisionId, search: data, paging: paging[0] })
		if (response.status === 200) {
			const data = response.data.data || []
			setAllOrder(data)
		}
	}

	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/vehicle/getAllActiveVehicle", config: { signal: controller.signal } },
			{ method: "get", url: "/route/getAllActiveRoute", config: { signal: controller.signal } },
			{ method: "get", url: "/vendor/getAllActiveVendor", config: { signal: controller.signal } },
			{ method: "get", url: "/employee/getAllEmployeeByRole", params: { role: "Driver" }, config: { signal: controller.signal } },
			{ method: "get", url: "/job/getAllJob", params: { paging: {} }, config: { signal: controller.signal } },
			{ method: "get", url: "/reason/getAllActiveReason", config: { signal: controller.signal } },
			{ method: "get", url: "/vehicleType/getAllActiveVehicleType", config: { signal: controller.signal } },
			{ method: "get", url: "/additionalPrice/getAllActiveAdditionalPrice", params: { type: "job,driver" }, config: { signal: controller.signal } },
			{ method: "get", url: "/division/getAllActiveDivision", config: { signal: controller.signal } },
			{ method: "get", url: "/employee/getAllEmployeeByPosition", params: { position: "พนักงานยกสินค้า" }, config: { signal: controller.signal } },
		])
		if (result[0].status === 200) {
			const data = result[0].data.data.records || []
			const groupedData = data.reduce((result, item) => {
				const key = item.vehicleTypeName;
				const value = item.vehicleTypeId;
				if (!result[key]) {
					result[key] = value;
				}
				return result;
			},
				{});
			setAllTruckType(Object.entries(groupedData).map(([key, value]) => { return { label: key, value: value } }))
			setAllLcnNo(data.map(e => ({ label: `${e.lcnNo} / ${e.routeName}`, value: e.id, data: e })))
			if (info.ctx.vehicleId) {
				dispatch(setInfo({ vehicleTypeId: data.find(e => e.id == info.ctx.vehicleId).vehicleTypeId }))
			}
		}
		if (result[1].status === 200) {
			const data = result[1].data.data.records || []
			setAllRoute(data.map(e => { return { label: `${e.source} - ${e.destination}`, value: e.id } }).sort((a, b) => a.label <= b.label))
		}
		if (result[2].status === 200) {
			const data = result[2].data.data.records || []
			setAllVendor([{ label: "own fleet", value: "own fleet" }, ...data.map(e => { return { label: e.name, value: e.id } })])
		}
		if (result[3].status === 200) {
			const data = result[3].data.data.records || []
			setAllDriver(data.map(e => { return { label: `${e.firstName} ${e.lastName}`, value: e.id, data: e } }))
		}
		if (result[4].status === 200) {
			const data = result[4].data.data.records || []
			setAllJob(data.filter(e => e.id !== info.ctx.id).map(e => { return { label: e.no, value: e.id, data: e } }))
		}
		if (result[5].status === 200) {
			const data = result[5].data.data.records || []
			setAllReason(data.map(data => ({ label: data.reason, value: data.id })) || [])
		}
		if (result[6].status === 200) {
			setAllVehicleType(result[6].data.data.records.map(data => ({ label: data.name, value: data.id })) || [])
		}
		if (result[7].status === 200) {
			const data = result[7].data.data.records || []
			setAllAdditionalPrice(data.map(data => ({ label: data.name, value: data.id })) || [])
		}
		if (result[8].status === 200) {
			const data = result[8].data.data.records || []
			setAllCustomer(data.map(e => ({ label: (e.customerName ? `${e.customerName} / ` : "") + e.name, value: e.id })))
		}
		if (result[9].status === 200) {
			const data = result[9].data.data.records || []
			setAllWorker(data.map(e => { return { label: `${e.firstName} ${e.lastName}`, value: e.id, data: e } }))
		}
	}

	useEffect(() => {
		const controller = new AbortController()
		fetchData(controller)
		return () => {
			controller.abort()
		}
	}, [])

	const onCloseModal = useCallback(() => {
		confirmPopup({
			title: "แจ้งเตือน",
			content: "ท่านต้องการยกเลิกการแก้ไขหรือไม่",
			onSubmit: () => {
				dispatch(clearInfoById(`job-${info.ctx.no}`))
				setMode("initial")
			}
		})
	}, [])

	const onCloseOrderModal = useCallback(() => {
		if (info[`job-${info.ctx.no}`]?.orders?.length) {
			confirmPopup({
				title: "แจ้งเตือน",
				content: "ท่านต้องการยกเลิกการแก้ไขหรือไม่",
				onSubmit: () => {
					dispatch(clearInfoById(`job-${info.ctx.no}`))
					setMode("initial")
				}
			})
		} else {
			dispatch(clearInfoById(`job-${info.ctx.no}`))
			setMode("initial")
		}
	}, [info])

	const searchFields = [
		{
			type: "autocomplete",
			className: "sm:!max-w-[350px]",
			props: {
				id: "divisionId",
				label: "ชื่อลูกค้า",
				options: allCustomer,
			}
		},
		{
			type: "date",
			props: {
				label: "ตั้งแต่วันที่",
				id: "startDate",
				maxDate: info.advanceSearch?.toDate || new Date(),
			}
		},
		{
			type: "date",
			props: {
				label: "ถึงวันที่",
				id: "toDate",
				minDate: info.advanceSearch?.startDate,
				maxDate: new Date(),
			}
		},
		{
			type: "autocomplete",
			props: {
				label: "ประเภทรถ",
				id: "vehicleType",
				options: allVehicleType,
			}
		},
		{
			type: "text",
			props: {
				label: "จังหวัดปลายทาง",
				id: "provinceName",
			}
		},
	]

	const inputForm = mode === "transfer" ? [
		{
			rowData: [
				{},
				{
					type: "autocomplete",
					className: "!pl-0",
					required: true,
					props: {
						id: "targetJob",
						label: "ใบงานที่ต้องการถ่ายโอน",
						options: allJob?.filter(e => e.data.status === STATUS_JOB_IN_TRANSIT)
					}
				},
				{}
			]
		},
		{
			rowData: [
				{
					type: "transferList",
					required: true,
					props: {
						id: "transferBills",
						label: "บิลที่ต้องการย้าย",
						leftData: info.ctx.orders?.filter(e => ![STATUS_ORDER_COMPLETE].includes(e.status)),
						labelFields: ["no", "invoiceNo"],
						keyField: "id",
						leftLabel: "เลือกบิล",
						rightLabel: "บิลที่เลือก"
					}
				}
			]
		},
		{
			noCol: true,
			align: "center",
			rowData: [
				{
					type: "button",
					props: {
						label: "ยกเลิก",
						onClick: () => {
							setMode("initial")
							dispatch(clearInfoById(`job-${info.ctx.no}`))
						}
					}
				},
				{
					type: "button",
					props: {
						label: "บันทึก",
						onClick: () => {
							updateOrder()
						},
						className: "!bg-bpTheme-buttonSubmit !ml-2"
					}
				}
			]
		}
	] : mode === "editCompleteTime" ? [
		info[`job-${info.ctx.no}`]?.orderNo && {
			rowData: [
				{
					type: "display",
					props: {
						id: "orderNo",
						label: "เลขที่ออเดอร์",
					}
				},
				{
					type: "datetime",
					props: {
						id: "completeTime",
						label: "เวลาที่จัดส่ง",
					}
				},
			]
		},
		{
			rowData: [
				{
					type: "dataTable",
					props: {
						columns: [
							{
								id: 'orderNo',
								label: 'เลขที่ออเดอร์',
								fields: ["orderNo"]
							},
							{
								id: 'senderName',
								label: 'ผู้ส่ง',
								fields: ["senderName"]
							},
							{
								id: 'pickDate',
								label: 'วันที่เข้ารับสินค้า',
								fields: ["pickDate"],
								type: "datetime"
							},
							{
								id: 'temperature',
								label: 'อุณหภูมิ',
								fields: ["temperature"]
							},
							{
								id: 'pickupAddress',
								label: 'ต้นทาง',
								fields: ["pickupAddress"]
							},
							{
								id: 'shippingAddress',
								label: 'ปลายทาง',
								fields: ["shippingAddress"]
							},
							{
								id: 'remark',
								label: 'หมายเหตุ',
								fields: ["remark"]
							},
						],
						collapse: {
							columns: [
								{
									id: 'detail',
									label: 'รายละเอียดสินค้า',
									fields: ["detail"]
								},
								{
									id: 'temperature',
									label: 'อุณหภูมิ',
									fields: ["temperature"],
									type: "number"
								},
								{
									id: 'pickupAddress',
									label: 'ที่อยู่รับสินค้า',
									fields: ["pickupAddress"]
								},
								{
									id: 'shippingAddress',
									label: 'ที่อยู่ส่งสินค้า',
									fields: ["shippingAddress"]
								},
								{
									id: 'totalPickup',
									label: 'จำนวนสินค้าที่รับ',
									fields: ["totalPickup"],
									type: "number"
								},
								{
									id: 'totalShipping',
									label: 'จำนวนสินค้าที่ส่ง',
									fields: ["totalShipping"],
									type: "number"
								},
								{
									id: 'unit',
									label: 'หน่วย',
									fields: ["unit"]
								},
								{
									id: 'remark',
									label: 'หมายเหตุ',
									fields: ["remark"]
								},
							],
							field: "items",
						},
						rows: allCompleteOrder,
						title: "ออเดอร์",
						tableContainerClassName: "max-h-[30vh]",
						size: "small",
						onClick: (e) => {
							dispatch(setInfoById({ id: `job-${info.ctx.no}`, payload: e }))
						},
						spliter: ", "
					}
				}
			]
		},
		{
			noCol: true,
			align: "center",
			rowData: [
				{
					type: "button",
					props: {
						label: "ย้อนกลับ",
						className: "!mr-2",
						onClick: async () => {
							const result = await get("/job/getJobById", { id: info.ctx.id })
							if (result.status === 200) {
								dispatch(setInfo(result.data.data))
							}
							dispatch(clearInfoById(`job-${info.ctx.no}`))
							setMode("initial")
						},
					},
				},
				info[`job-${info.ctx.no}`]?.orderNo && {
					type: "button",
					props: {
						label: "ตกลง",
						onClick: () => {
							confirmPopup({
								onSubmit: async () => {
									const data = normalizeData(info[`job-${info.ctx.no}`])
									let response = await post("/order/updateOrderCompleteTime", { id: data.id, completeTime: data.completeTime })
									if (response.status === 200) {
										dispatch(clearInfoById(`job-${info.ctx.no}`))
										enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
										const result = await get('/order/getCompleteOrder', { jobId: info.ctx.id })
										if (result.status === 200) {
											const data = result.data.data
											setAllCompleteOrder(data)
										}
									} else {
										enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
									}
								},
								onCancel: () => {
									enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
								}
							})
						}
					}
				}
			]
		}
	] : mode === "closeJob" ? [
		{
			rowData: [
				{},
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "status",
						label: "สถานะ",
						options: [
							{ label: "Complete", value: STATUS_JOB_COMPLETE },
							{ label: "Reject", value: STATUS_JOB_REJECT }
						]
					}
				},
				{}
			]
		},
		(
			info[`job-${info.ctx.no}`]?.status === STATUS_JOB_REJECT ? {
				rowData: [
					{},
					{
						type: "autocomplete",
						props: {
							id: "reasonId",
							label: "เหตุผล",
							options: allReason
						}
					},
					{}
				]
			} : {}
		),
		{
			rowData: [
				{},
				{
					type: "datetime",
					props: {
						id: "pickUpCompleteTime",
						label: "เวลาที่รับ",
						maxDate: info[`job-${info.ctx.no}`]?.completeTime
					}
				},
				{}
			]
		},
		{
			rowData: [
				{},
				{
					type: "datetime",
					props: {
						id: "completeTime",
						label: "เวลาที่จัดส่ง",
						minDate: info[`job-${info.ctx.no}`]?.pickUpCompleteTime
					}
				},
				{}
			]
		},
		{
			noCol: true,
			align: "center",
			rowData: [
				{
					type: "button",
					props: {
						label: "ยกเลิก",
						onClick: () => {
							setMode("initial")
							dispatch(clearInfoById(`job-${info.ctx.no}`))
						}
					}
				},
				{
					type: "button",
					props: {
						label: "บันทึก",
						onClick: () => {
							saveCloseJob()
						},
						className: "!bg-bpTheme-buttonSubmit !ml-2"
					}
				}
			]
		}
	] : mode === "additionalPrice" ? [
		{
			rowData: [
				{
					type: "list",
					props: {
						id: "additionalPrice",
						showDuplicate: mode === "create",
						data: [
							{
								rowData: [
									{
										type: "autocomplete",
										props: {
											id: "addPriceId",
											label: "ค่าใช้จ่ายเพิ่มเติม",
											options: allAdditionalPrice,
										},
										md: 5
									},
									{
										type: "number",
										props: {
											id: "draftAmount",
											label: "จำนวนที่ขอเบิก",
										},
										md: 2.5
									},
									{
										type: "number",
										props: {
											id: "usedAmount",
											label: "จำนวนที่จ่ายจริง",
										},
										md: 2.5
									},
									{
										type: "custom",
										component: <Box className="h-[inherit] flex items-center justify-center"> บาท </Box>,
										md: 2
									}
								]
							}
						],
					}
				},
			]
		},
		{
			noCol: true,
			align: "center",
			rowData: [
				{
					type: "button",
					props: {
						label: "ยกเลิก",
						onClick: () => {
							setMode("initial")
							dispatch(clearInfoById(`job-${info.ctx.no}`))
						}
					}
				},
				{
					type: "button",
					props: {
						label: "บันทึก",
						onClick: () => {
							saveAdditionalPrice()
						},
						className: "!bg-bpTheme-buttonSubmit !ml-2"
					}
				},
			]
		},
	] : [
		{
			rowData: [
				{
					type: "dataTable",
					props: {
						columns: [
							{
								id: 'orderNo',
								label: 'เลขที่ออเดอร์',
								fields: ["orderNo"]
							},
							{
								id: 'senderName',
								label: 'ผู้ส่ง',
								fields: ["senderName"]
							},
							{
								id: 'pickDate',
								label: 'วันที่เข้ารับสินค้า',
								fields: ["pickDate"],
								type: "datetime"
							},
							{
								id: 'temperature',
								label: 'อุณหภูมิ',
								fields: ["temperature"]
							},
							{
								id: 'pickupAddress',
								label: 'ต้นทาง',
								fields: ["pickupAddress"]
							},
							{
								id: 'shippingAddress',
								label: 'ปลายทาง',
								fields: ["shippingAddress"]
							},
							{
								id: 'remark',
								label: 'หมายเหตุ',
								fields: ["remark"]
							},
						],
						collapse: {
							columns: [
								{
									id: 'detail',
									label: 'รายละเอียดสินค้า',
									fields: ["detail"]
								},
								{
									id: 'temperature',
									label: 'อุณหภูมิ',
									fields: ["temperature"],
									type: "number"
								},
								{
									id: 'pickupAddress',
									label: 'ที่อยู่รับสินค้า',
									fields: ["pickupAddress"]
								},
								{
									id: 'shippingAddress',
									label: 'ที่อยู่ส่งสินค้า',
									fields: ["shippingAddress"]
								},
								{
									id: 'totalPickup',
									label: 'จำนวนสินค้าที่รับ',
									fields: ["totalPickup"],
									type: "number"
								},
								{
									id: 'totalShipping',
									label: 'จำนวนสินค้าที่ส่ง',
									fields: ["totalShipping"],
									type: "number"
								},
								{
									id: 'unit',
									label: 'หน่วย',
									fields: ["unit"]
								},
								{
									id: 'remark',
									label: 'หมายเหตุ',
									fields: ["remark"]
								},
							],
							field: "items",
						},
						id: "orders",
						rows: allOrder,
						multiSelect: true,
						title: "ออเดอร์",
						controller: (paging) => get("/order/getOrderToPlan", { divisionId: info.ctx.divisionId, vehicleTypeId: info.ctx.vehicleTypeId, search: searchInfo, paging }),
						customToolbar: {
							component: AdvanceSearch,
							props: {
								handleSearch: search,
								components: searchFields
							}
						},
						state: { paging },
						tableContainerClassName: "max-h-[500px]",
						size: "small",
						hideSelected: true
					}
				}
			]
		},
		{
			noCol: true,
			align: "center",
			rowData: [
				{
					type: "button",
					props: {
						label: "ย้อนกลับ",
						className: "!mr-2",
						onClick: onCloseOrderModal,
					},
				},
				{
					type: "button",
					props: {
						label: "ตกลง",
						onClick: () => {
							if (info[`job-${info.ctx.no}`]?.orders?.length) {
								confirmPopup({
									onSubmit: async () => {
										const data = normalizeData(info[`job-${info.ctx.no}`])
										let response = await post("/job/addOrders", { jobId: info.ctx.id, orders: data.orders, status: STATUS_ORDER_PLANNED })
										if (response.status === 200) {
											setMode("initial")
											dispatch(clearInfoById(`job-${info.ctx.no}`))
											enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
											const result = await get("/job/getJobById", { id: info.ctx.id })
											if (result.status === 200) {
												dispatch(setInfo(result.data.data))
											}
										} else {
											enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
										}
									},
									onCancel: () => {
										enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
									}
								})
							} else {
								enqueueSnackbar('โปรดเลือกอย่างน้อย 1 รายการ', { variant: "info" })
							}
						}
					}
				}
			]
		}
	]

	const handleCopyText = () => {
		const order = info.ctx.orders && info.ctx.orders.length > 0 ? info.ctx.orders[0] : {};
		const data = `วันที่ : ${info.ctx.etdTime ? format(toBuddhistYear(new Date(info.ctx.etdTime)), 'dd MMMM yyyy', { locale: th }) : '-'}\nสถานที่รับ-ส่ง : ${(order.pickupAddress || 'ไม่ระบุ')} --> ${order.shippingAddress || 'ไม่ระบุ'} \n\nอุณหภูมิขนส่ง :  ${order.temperature ? (order.temperature > 0 ? '+' : '') + `${order.temperature} °C` : '-'} \nเวลารับ : ${info.ctx.etdTime ? moment(new Date(info.ctx.etdTime)).format("HH:mm") : '-'} \nทะเบียนรถ : ${info.ctx.lcnNo || '-'} \nชื่อคนขับ : ${info.ctx.driverName || '-'} \nเบอร์โทรคนขับ : ${info.ctx.driverTel || '-'} \nID No. : ${info.ctx.driverNo || '-'} \nหมายเหตุ : ${info.ctx.remark || '-'}`
		copy(data).then(() => {
			enqueueSnackbar('คัดลอกแล้ว !', { variant: "success" })
		});
	};

	const copyDataForm = [
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "status",
						label: "สถานะ",
					}
				},
				{},
				{
					type: "button",
					className: "text-right",
					props: {
						label: "คัดลอกข้อมูล",
						onClick: handleCopyText,
					}
				}
			]
		}
	]

	const form = mode !== "edit" ? [
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "vendorId",
						label: "บริษัทรถร่วม",
						valuesMap: allVendor.reduce((acc, cur) => {
							acc[cur.value] = cur.label;
							return acc;
						},
							{})
					}
				},
				{
					type: "display",
					props: {
						id: "vehicleTypeName",
						label: "ประเภทรถ",
					}
				},
				{
					type: "display",
					props: {
						id: "lcnNo",
						label: "ทะเบียนรถ",
					}
				},
			]
		},
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "driverName",
						label: "คนขับรถ",
					}
				},
				{
					type: "display",
					props: {
						id: "driverName2",
						label: "คนขับรถ (สำรอง)",
					}
				},
				{}
			]
		},
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "workerName",
						label: "พนักงานยกของ",
					}
				},
				{
					type: "display",
					props: {
						id: "workerName2",
						label: "พนักงานยกของ (สำรอง)",
					}
				},
				{}
			]
		},
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "etdTime",
						type: "datetime",
						label: "วันที่จะเข้ารับสินค้า",
					}
				},
				{
					type: "display",
					props: {
						id: "etaTime",
						type: "datetime",
						label: "วันที่จบงาน",
					}
				},
				{
					type: "display",
					props: {
						id: "takeCarTime",
						type: "datetime",
						label: "วันที่รับรถ",
					}
				}
			]
		},
		{
			isCollapse: true,
			label: "รายการค่าใช้จ่ายเพิ่มเติม",
			collapseData: [
				{
					rowData: [
						{
							type: "list",
							props: {
								id: "additionalPrice",
								showDuplicate: mode === "create",
								data: [
									{
										rowData: [
											{
												type: "autocomplete",
												props: {
													id: "addPriceId",
													label: "ค่าใช้จ่ายเพิ่มเติม",
													options: allAdditionalPrice,
												},
												md: 5
											},
											{
												type: "number",
												props: {
													id: "draftAmount",
													label: "จำนวนที่ขอเบิก",
												},
												md: 2.5
											},
											{
												type: "number",
												props: {
													id: "usedAmount",
													label: "จำนวนที่จ่ายจริง",
												},
												md: 2.5
											},
											{
												type: "custom",
												component: <Box className="h-[inherit] flex items-center justify-center"> บาท </Box>,
												md: 2
											}
										]
									}
								],
								readOnly: true
							}
						},
					]
				},
			]
		},
		{
			rowData: [
				{
					type: "display",
					props: {
						id: "remark",
						label: "หมายเหตุ",
					}
				}
			]
		}
	] : [
		{
			rowData: [
				{
					type: "autocomplete",
					props: {
						id: "vendorId",
						label: "บริษัท",
						options: allVendor
					}
				},
				{
					type: "autocomplete",
					props: {
						id: "vehicleTypeId",
						label: "ประเภทรถ",
						options: allTruckType,
						onChange: () => {
							dispatch(setInfoById({ id: `job-${info.ctx.no}`, payload: { vehicleId: null } }))
						},
					}
				},
				{
					type: "autocomplete",
					props: {
						id: "vehicleId",
						label: "ทะเบียนรถ",
						options: allLcnNo,
						filterOptions: (e) => info[`job-${info.ctx.no}`]?.vehicleTypeId === e.data?.vehicleTypeId && (info[`job-${info.ctx.no}`]?.vendorId === "own fleet" ? !e.data?.vendorId : info[`job-${info.ctx.no}`]?.vendorId === e.data?.vendorId),
						onChange: (id) => {
							if (!info[`job-${info.ctx.no}`]?.driverId) {
								const driverId = allLcnNo.find(e => e.value === id)?.data?.driverId
								dispatch(setInfoById({ id: `job-${info.ctx.no}`, payload: { driverId } }))
							}
						},
						relateId: "vendorId"
					}
				},
			]
		},
		{
			rowData: [
				{
					type: "autocomplete",
					props: {
						id: "driverId",
						label: "พนักงานขับรถ",
						options: allDriver,
						filterOptions: (e) => (info[`job-${info.ctx.no}`]?.vendorId === "own fleet" ? !e.data?.vendorId : info[`job-${info.ctx.no}`]?.vendorId === e.data?.vendorId) && info[`job-${info.ctx.no}`]?.driverId2 !== e.data?.id,
						relateId: "vendorId"
					}
				},
				{
					type: "autocomplete",
					props: {
						id: "driverId2",
						label: "พนักงานขับรถ (สำรอง)",
						options: allDriver,
						filterOptions: (e) => (info[`job-${info.ctx.no}`]?.vendorId === "own fleet" ? !e.data?.vendorId : info[`job-${info.ctx.no}`]?.vendorId === e.data?.vendorId) && info[`job-${info.ctx.no}`]?.driverId !== e.data?.id,
						relateId: "vendorId"
					}
				},
				{}
			]
		},
		{
			rowData: [
				{
					type: "autocomplete",
					props: {
						id: "workerId",
						label: "พนักงานยกของ",
						options: allWorker,
						filterOptions: (e) => (info[`job-${info.ctx.no}`]?.vendorId === "own fleet" ? !e.data?.vendorId : info[`job-${info.ctx.no}`]?.vendorId === e.data?.vendorId) && info[`job-${info.ctx.no}`]?.workerId2 !== e.data?.id,
						relateId: "vendorId"
					}
				},
				{
					type: "autocomplete",
					props: {
						id: "workerId2",
						label: "พนักงานยกของ (สำรอง)",
						options: allWorker,
						filterOptions: (e) => (info[`job-${info.ctx.no}`]?.vendorId === "own fleet" ? !e.data?.vendorId : info[`job-${info.ctx.no}`]?.vendorId === e.data?.vendorId) && info[`job-${info.ctx.no}`]?.workerId !== e.data?.id,
						relateId: "vendorId",
					}
				},
				{}
			]
		},
		{
			rowData: [
				{
					type: "datetime",
					props: {
						id: "etdTime",
						label: "วันที่จะเข้ารับสินค้า",
						minDate: roleUser === "Owner" || new Date(),
					}
				},
				{
					type: "datetime",
					props: {
						id: "etaTime",
						label: "วันที่จบงาน",
						minDate: info["job-new"]?.etdTime,
					}
				},
				{
					type: "datetime",
					props: {
						id: "takeCarTime",
						label: "วันที่รับรถ",
						maxDate: info["job-new"]?.etdTime,
					}
				}
			]
		},
		{
			isCollapse: true,
			label: "รายการค่าใช้จ่ายเพิ่มเติม",
			collapseData: [
				{
					rowData: [
						{
							type: "list",
							props: {
								id: "additionalPrice",
								showDuplicate: mode === "create",
								data: [
									{
										rowData: [
											{
												type: "autocomplete",
												props: {
													id: "addPriceId",
													label: "ค่าใช้จ่ายเพิ่มเติม",
													options: allAdditionalPrice,
												},
												md: 5
											},
											{
												type: "number",
												props: {
													id: "draftAmount",
													label: "จำนวนที่ขอเบิก",
												},
												md: 2.5
											},
											{
												type: "number",
												props: {
													id: "usedAmount",
													label: "จำนวนที่จ่ายจริง",
												},
												md: 2.5
											},
											{
												type: "custom",
												component: <Box className="h-[inherit] flex items-center justify-center"> บาท </Box>,
												md: 2
											}
										]
									}
								],
							}
						},
					]
				},
			]
		},
		{
			rowData: [
				{
					type: "text",
					props: {
						id: "remark",
						label: "หมายเหตุ",
						variant: "outlined",
						multiline: true,
						maxRows: 4
					}
				}
			]
		},
	]

	const editForm = [
		...copyDataForm,
		...form,
		{
			rowData: [
				{
					type: "dataTable",
					props: {
						columns: [
							{
								id: 'orderNo',
								label: 'เลขที่ออเดอร์',
								fields: ["orderNo"]
							},
							{
								id: 'senderName',
								label: 'ผู้ส่ง',
								fields: ["senderName"]
							},
							{
								id: 'pickDate',
								label: 'วันที่เข้ารับสินค้า',
								fields: ["pickDate"],
								type: "datetime"
							},
							{
								id: 'temperature',
								label: 'อุณหภูมิ',
								fields: ["temperature"]
							},
							{
								id: 'pickupAddress',
								label: 'ต้นทาง',
								fields: ["pickupAddress"]
							},
							{
								id: 'shippingAddress',
								label: 'ปลายทาง',
								fields: ["shippingAddress"]
							},
							{
								id: 'remark',
								label: 'หมายเหตุ',
								fields: ["remark"]
							},
						],
						collapse: {
							columns: [
								{
									id: 'detail',
									label: 'รายละเอียดสินค้า',
									fields: ["detail"]
								},
								{
									id: 'temperature',
									label: 'อุณหภูมิ',
									fields: ["temperature"],
									type: "number"
								},
								{
									id: 'pickupAddress',
									label: 'ที่อยู่รับสินค้า',
									fields: ["pickupAddress"]
								},
								{
									id: 'shippingAddress',
									label: 'ที่อยู่ส่งสินค้า',
									fields: ["shippingAddress"]
								},
								{
									id: 'totalPickup',
									label: 'จำนวนสินค้าที่รับ',
									fields: ["totalPickup"],
									type: "number"
								},
								{
									id: 'totalShipping',
									label: 'จำนวนสินค้าที่ส่ง',
									fields: ["totalShipping"],
									type: "number"
								},
								{
									id: 'unit',
									label: 'หน่วย',
									fields: ["unit"]
								},
								{
									id: 'remark',
									label: 'หมายเหตุ',
									fields: ["remark"]
								},
							],
							field: "items",
						},
						rows: info.ctx.orders,
						multiSelect: false,
						tableContainerClassName: "max-h-[500px]",
						size: "small",
					}
				}
			]
		},
		mode === "edit" ? {
			noCol: true,
			className: "flex justify-between",
			rowData: [
				{
					type: "button",
					props: {
						label: "ย้อนกลับ",
						onClick: () => {
							setMode("initial")
							dispatch(clearInfoById(`job-${info.ctx.no}`))
						}
					}
				},
				{
					type: "button",
					props: {
						label: "บันทึก",
						className: "!bg-bpTheme-buttonSubmit !ml-2",
						onClick: () => {
							saveData()

						}
					}
				}
			]
		} : {
			noCol: true,
			align: "center",
			rowData: [
				info.ctx.status === STATUS_JOB_NEW && {
					type: "button",
					props: {
						label: "เพิ่มออเดอร์",
						className: "!mr-2",
						onClick: async () => {
							const result = await get("/order/getOrderToPlan", { jobId: info.ctx.id, divisionId: info.ctx.divisionId, vehicleTypeId: info.ctx.vehicleTypeId })
							if (result.status === 200) {
								const data = result.data.data || []
								setAllOrder(data)
								setMode("add")
							}
						}
					}
				},
				info.ctx.status === STATUS_JOB_NEW && !info.ctx.dispatchJob && {
					type: "button",
					props: {
						label: "ปล่อยงาน",
						className: "!mr-2",
						onClick: async () => {
							dispatchJob(info.ctx.id)
						}
					}
				},
				info.ctx.status !== STATUS_JOB_CANCELED && {
					type: "button",
					props: {
						label: "แก้ไขงาน",
						className: "!mr-2",
						onClick: () => {
							dispatch(setInfoById({ id: `job-${info.ctx.no}`, payload: { ...info.ctx } }))
							setMode("edit")
						}
					}
				},
				info.ctx.orders?.some(e => e.status === STATUS_ORDER_COMPLETE) && {
					type: "button",
					props: {
						label: "แก้ไขเวลาปิดงาน",
						className: "!mr-2",
						onClick: async () => {
							const result = await get('/order/getCompleteOrder', { jobId: info.ctx.id })
							if (result.status === 200) {
								const data = result.data.data
								setAllCompleteOrder(data)
								setMode("editCompleteTime")
							}
						}
					}
				},
				// info.ctx.status !== STATUS_JOB_NEW && info.ctx.status !== STATUS_JOB_CANCELED && !info.ctx.isHaveInvoice && {
				// 	type: "button",
				// 	props: {
				// 		label: "ค่าใช้จ่ายเพิ่มเติม",
				// 		className: "!mr-2",
				// 		onClick: () => {
				// 			dispatch(setInfoById({ id: `job-${info.ctx.no}`, payload: { additionalPrice: info.ctx.additionalPrice } }))
				// 			setMode("additionalPrice")
				// 		}
				// 	}
				// },
				{
					type: "button",
					props: {
						label: "พิมพ์ใบสั่งปฏิบัติงาน",
						className: "!mr-2",
						onClick: async () => {
							await download("/doc/getCoverReport", { jobId: info.ctx.id, type: "division" })
						}
					}
				},
				!finalStatus.includes(info.ctx.status) && {
					type: "button",
					props: {
						label: "ปิดงาน",
						className: "!mr-2",
						onClick: () => {
							setMode("closeJob")
						}
					}
				}
			]
		}
	]

	const dispatchJob = (id) => {
		confirmPopup({
			title: "แจ้งเตือน",
			content: "ท่านต้องการปล่อยงานทั้งหมดหรือไม่",
			onSubmit: async () => {
				let response
				response = await post("/job/dispatchJob", {
					jobs: [{ id }]
				})

				if (response.status === 200) {
					setMode("initial")
					setJobMode("initial")
					dispatch(clearInfoById(`job-${info.ctx.no}`))
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					fetchData(new AbortController())
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
		})
	}

	const saveData = useCallback(() => {
		save({
			data: info[`job-${info.ctx.no}`],
			onSubmit: async () => {
				const data = normalizeData(info[`job-${info.ctx.no}`])
				let response = await post("/job/updateJob", { ...data, additionalPriceDelivery: data?.additionalPrice })
				if (response.status === 200) {
					setMode("initial")
					setJobMode("initial")
					dispatch(clearInfoById(`job-${info.ctx.no}`))
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					fetchData(new AbortController())
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info])

	const updateOrder = useCallback(() => {
		save({
			data: info[`job-${info.ctx.no}`],
			onSubmit: async () => {
				const data = normalizeData(info[`job-${info.ctx.no}`])
				let response = await post("/job/transferBills", { prevJobId: info.ctx.id, jobId: data.targetJob, orders: data.transferBills })
				if (response.status === 200) {
					setMode("initial")
					dispatch(clearInfoById(`job-${info.ctx.no}`))
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					const result = await get("/job/getJobById", { id: info.ctx.id })
					if (result.status === 200) {
						dispatch(setInfo(result.data.data))
					}
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info])

	const saveCloseJob = useCallback(() => {
		save({
			data: info[`job-${info.ctx.no}`],
			onSubmit: async () => {
				const data = normalizeData(info[`job-${info.ctx.no}`])
				let response = await post("/bill/updateBillCloseJob", { jobId: info.ctx.id, status: data.status, reasonId: data.status === STATUS_JOB_REJECT ? data.reasonId : null, completeTime: data.completeTime, pickUpCompleteTime: data.pickUpCompleteTime })
				if (response.status === 200) {
					setMode("initial")
					dispatch(clearInfoById(`job-${info.ctx.no}`))
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					const result = await get("/job/getJobById", { id: info.ctx.id })
					if (result.status === 200) {
						dispatch(setInfo(result.data.data))
					}
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info])

	const saveAdditionalPrice = useCallback(() => {
		save({
			data: info[`job-${info.ctx.no}`],
			onSubmit: async () => {
				const data = normalizeData(info[`job-${info.ctx.no}`])
				let response = await post("/job/updateAdditionalPriceJob", { id: info.ctx.id, additionalPriceDelivery: data?.additionalPrice })
				if (response.status === 200) {
					setMode("initial")
					dispatch(clearInfoById(`job-${info.ctx.no}`))
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					const result = await get("/job/getJobById", { id: info.ctx.id })
					if (result.status === 200) {
						dispatch(setInfo(result.data.data))
					}
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info])

	return (
		<>
			<Form id={mode === "edit" ? `job-${info.ctx.no}` : undefined} title={mode === "edit" ? "แก้ไขงาน " + (info.ctx.no || "") : "ข้อมูลงาน " + (info.ctx.no || "")} name="user-form" data={editForm}></Form>
			<Modal open={mode === "add"} onClose={onCloseOrderModal} className="form-modal">
				<Form id={`job-${info.ctx.no}`} title={"เพิ่มออเดอร์"} name="job-form" data={inputForm} className="h-full !p-0"></Form>
			</Modal>
			<Modal open={mode === "additionalPrice"} onClose={onCloseModal} className="form-modal">
				<Form id={`job-${info.ctx.no}`} title={"รายการค่าใช้จ่ายเพิ่มเติม"} name="job-form" data={inputForm}></Form>
			</Modal>
			<Modal open={mode === "transfer"} onClose={onCloseModal} className="form-modal">
				<Form id={`job-${info.ctx.no}`} title={"ถ่ายโอนงาน " + (info.ctx.no || "")} name="job-form" data={inputForm}></Form>
			</Modal>
			<Modal open={mode === "editCompleteTime"} onClose={onCloseModal} className="form-modal">
				<Form id={`job-${info.ctx.no}`} title={"แก้ไขเวลาปิดงาน"} name="job-form" data={inputForm}></Form>
			</Modal>
			<Modal open={mode === "closeJob"} onClose={onCloseModal} className="form-modal" contentClassName="min-w-full md:!min-w-[750px]">
				<Form id={`job-${info.ctx.no}`} title={"ปิดงาน " + (info.ctx.no || "")} name="job-form" data={inputForm}></Form>
			</Modal>
		</>
	)
}
